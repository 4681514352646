import './Footer.css'

export default function Footer() {
  return (
    <footer className="theFooter">
      <p>
        <small>
          App ideata da Associazione Amici della Mongolfiera per Lu.I.S. e co-finanziata da Chiesa Valdese.<br />{new Date().getFullYear()} - v{process.env.REACT_APP_VERSION}
        </small>
      </p>
    </footer>
  )
}
