import { routes } from './routes'

const menus = [
  {
    slug: 'user',
    title: 'Utenti',
    menu: [
      {
        title: 'Mostra tutti',
        url: routes.USERS
      },
      {
        title: 'Aggiungi nuovo utente',
        url: routes.NEWUSER
      }
    ]
  },
  {
    slug: 'faq',
    title: 'Materiali informativi',
    menu: [
      {
        title: 'Mostra tutti',
        url: routes.FAQS
      },
      {
        title: 'Aggiungi un nuovo materiale',
        url: routes.NEWFAQ
      }
    ]
  },
  {
    slug: 'school',
    title: 'Scuole',
    menu: [
      {
        title: 'Mostra tutte',
        url: routes.SCHOOLS
      },
      {
        title: 'Aggiungi nuova scuola',
        url: routes.NEWSCHOOL
      }
    ]
  },
  {
    slug: 'association',
    title: 'Associazioni',
    menu: [
      {
        title: 'Mostra tutte',
        url: routes.ASSOCIATIONS
      },
      {
        title: 'Aggiungi nuova associazione',
        url: routes.NEWASSOCIATION
      }
    ]
  },
  {
    slug: 'event',
    title: 'Eventi',
    menu: [
      {
        title: 'Mostra tutti',
        url: routes.EVENTS
      },
      {
        title: 'Aggiungi nuovo evento',
        url: routes.NEWEVENT
      }
    ]
  },
]

export { menus }
