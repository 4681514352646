import { Field, FieldArray, useFormikContext } from 'formik'
import { Choices, Searchable, Dating, FillableSlider, Money, Files, Popup } from './fields'
import {
  Stack,
  StackDivider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Checkbox,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Textarea,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import { AddIcon, DeleteIcon, } from '@chakra-ui/icons'
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete'
import _ from 'lodash'

const TheFields = ({ lang, fields, setFields, props, what, nested }) => {
  const { values, setValues, setFieldValue } = useFormikContext()

  const setNewValues = ( newfields ) => {
    setValues(_.merge(values, newfields), false )
    console.log('added', values)
  }

  return (
    <Stack direction="column" spacing={nested ? 6 : 10} divider={<StackDivider borderColor="gray.200" />}>
    {fields.map(item => {

      if (item.hiddenIfTrue && props.values[item.name]) {
        return null
      } else if (item.hiddenIfNew && what.id === 'new') {
        return null
      } else {
        let theName = item.name
        if (nested) {
          theName = `${nested.parent}.${nested.index}.${theName}`
        }
        if (lang) {
          theName = `${lang}.${theName}`
        }
        return(
          <Field key={theName} name={theName}>
          {({ field, form, meta }) => {
            // console.log(item.type, field)
            return(
            <FormControl isInvalid={meta.error && meta.name} isDisabled={form.isSubmitting}>
              <FormLabel htmlFor={theName}>{item.label}</FormLabel>
              {item.type === 'email' &&
                <Input
                  {...field}
                  id={theName}
                  type="email"
                  placeholder={item.placeholder}
                  />
              }
              {item.type === 'password' &&
                <InputGroup>
                  <Input
                    {...field}
                    pr="7rem"
                    id={theName}
                    type={fields.filter(e => e.id === item.id)[0].show ? 'text' : 'password'}
                    placeholder={item.placeholder}
                    />
                  <InputRightElement width="6.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => {
                        setFields(fields.map(e => {
                          if (e.id === item.id) {
                            return {
                              ...e,
                              show: !e.show
                            }
                          } else {
                            return e
                          }
                        }))}}>
                      {fields.filter(e => e.id === item.id)[0].show ? 'Nascondi' : 'Mostra'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              }
              {item.type === 'button' &&
                <Button {...field} id={theName} type="primary" onClick={(event) => item.action(event, what.id, form.values)}>
                  {item.cta}
                </Button>
              }
              {item.type === 'select' &&
                <Select {...field} id={theName} placeholder={item.placeholder}>
                  {item.options.map(option =>
                    <option key={option.value} value={option.value}>{option.label}</option>
                  )}
                </Select>
              }
              {item.type === 'geo' &&
                <>
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                    apiOptions={{ language: 'it', region: 'it' }}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ['it'],
                      }
                    }}
                    debounce={800}
                    minLengthAutocomplete={3}
                    selectProps={{
                      name: field.name,
                      defaultInputValue: field.value,
                      placeholder: item.placeholder,
                      onChange: (action) => {
                        geocodeByAddress(action.label)
                          .then((result) => {
                            let newfields = {}
                            const fieldName = theName.split('.').pop()
                            if (lang) {
                              newfields[lang] = {
                                _lat: result[0].geometry.location.lat(),
                                _lng: result[0].geometry.location.lng()
                              }
                              newfields[lang][fieldName] = action.label
                            } else {
                              newfields = {
                                _lat: result[0].geometry.location.lat(),
                                _lng: result[0].geometry.location.lng()
                              }
                              newfields[fieldName] = action.label
                            }
                            setNewValues(newfields)
                          })
                      }
                    }}
                    onLoadFailed={(error) => (
                      console.error("Could not inject Google script", error)
                    )}
                  />
                </>
              }
              {item.type === 'checkbox' &&
                <Checkbox {...field} id={theName} defaultIsChecked={field.value}>{item.cta}</Checkbox>
              }
              {item.type === 'number' &&
                <NumberInput {...field} id={theName} placeholder={item.placeholder} min={item.min} max={item.max}>
                  <NumberInputField value={field.value} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              }
              {item.type === 'searchable' &&
                <Searchable field={field} item={item} helpers={{setFieldValue}} />
              }
              {item.type === 'array' &&
                  <FieldArray
                    name={theName}
                    render={arrayHelpers => {
                      let testing = false
                      let nestedItems = []
                      if (!lang) {
                        if (item.name in form.values) {
                          testing = true
                          nestedItems = form.values[item.name]
                        }
                      } else {
                        if (lang in form.values && item.name in form.values[lang]) {
                          testing = true
                          nestedItems = form.values[lang][item.name]
                        }
                      }
                      return(
                      <>

                      {testing &&
                        <Accordion
                          allowToggle
                          size="sm"
                          colorScheme="grey">
                        {nestedItems.map((nestItem, index) =>
                          <AccordionItem
                            key={index}
                            borderRadius="md"
                            borderWidth="1px"
                            mb={4}>
                            <div>
                              <AccordionButton>
                                <Box flex="1" textAlign="left" fontSize={'1.2em'} fontWeight={700}>
                                  {
                                    form.values[lang][item.name][index][fields.filter(e => e.name === item.name)[0].array.filter(e => e.isTitle)[0].name] ?
                                      `${index+1}) ${form.values[lang][item.name][index][fields.filter(e => e.name === item.name)[0].array.filter(e => e.isTitle)[0].name]}`
                                      :
                                      `${index+1}) Titolo elemento`
                                  }
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </div>
                            <AccordionPanel px={4} py={4}>
                              <TheFields key={index} lang={lang} fields={item.array} props={props} what={what} nested={{parent:item.name, index: index}} />
                              <Button
                                mt={5}
                                size="sm"
                                isFullWidth
                                colorScheme="red"
                                leftIcon={<DeleteIcon />}
                                variant="outline"
                                onClick={() => arrayHelpers.remove(index)}>
                                Rimuovi
                              </Button>
                            </AccordionPanel>
                          </AccordionItem>
                        )}
                        </Accordion>
                      }

                      <Stack direction="column" spacing={4}>
                      <Button
                        colorScheme="green"
                        leftIcon={<AddIcon />}
                        variant="outline"
                        onClick={(form) => {
                          console.log('form', form.values)
                          let pushIt = {}
                          for (const arrayItem of item.array) {
                            pushIt[arrayItem.name] = ''
                          }
                          arrayHelpers.push(pushIt)
                        }}>
                        Aggiungi {item.label.toLowerCase()}
                      </Button>
                      </Stack>
                      </>

                  )}} />
              }
              {item.type === 'textarea' &&
                <Textarea {...field} id={theName} placeholder={item.placeholder} resize="vertical" style={{
                  direction: lang === 'ar' ? 'rtl' : 'ltr'
                }} />
              }
              {item.type === 'date' &&
                <Dating field={field} item={item} helpers={{setFieldValue}} />
              }
              {item.type === 'url' &&
                <Input
                  {...field}
                  id={theName}
                  placeholder={item.placeholder}
                  type="url"
                  />
              }
              {item.type === 'slider' &&
                <FillableSlider field={field} item={item} helpers={{setFieldValue}} />
              }
              {item.type === 'files' &&
                <Files field={field} item={item} helpers={{setFieldValue}} />
              }
              {item.type === 'money' &&
                <Money field={field} item={item} helpers={{setFieldValue}} />
              }
              {item.type === 'choices' &&
                <Choices field={field} item={item} helpers={{setFieldValue}} />
              }
              {item.type === 'modal' &&
                <Popup field={field} item={item} helpers={{values}} />
              }
              {item.type === 'text' &&
                <Input
                  {...field}
                  id={theName}
                  placeholder={item.placeholder}
                  style={{
                    direction: lang === 'ar' ? 'rtl' : 'ltr'
                  }}
                  />
              }
              <FormHelperText>{item.hint}</FormHelperText>
              <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
          )}}
         </Field>
        )
      }
   })}
   </Stack>
  )
}
export default TheFields
