import { Stack } from '@chakra-ui/react'
import 'flagpack/dist/flagpack.css'

import { langs } from '../utils/fields'

const LanguageSelector = ({ lang, showCountry, size }) => {
  let cc
  if (lang === 'en') {
    cc = 'gb'
  } else if (lang === 'es') {
    cc = 'es'
  } else if (lang === 'ar') {
    cc = 'ar'
  } else {
    cc = 'it'
  }

  return (
    <Stack direction="row" spacing={4}>
      <span className={`fp fp-${size} fp-rounded ${cc}`} />
      {showCountry && <p>{langs.filter(e => e.value === lang)[0].label}</p>}
    </Stack>
  )
}

export default LanguageSelector
