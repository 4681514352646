import { routes } from './routes'

const lists = {
  // user list
  users: {
    title: 'Tutti gli utenti',
    link: routes.USER,
    linkNew: routes.NEWUSER,
    columns: [
      {
        name: 'email',
        label: 'Email',
        type: 'text',
        sort: 'asc'
      },
      {
        name: 'emailVerified',
        label: 'Utente verificato',
        type: 'bool'
      },
      {
        name: '_lastSignIn',
        label: 'Ultimo accesso',
        type: 'date'
      },
      {
        name: '_createdAt',
        label: 'Creato il',
        type: 'date',
      }
    ]
  },
  // faq list
  faqs: {
    title: 'Tutti i materiali informativi',
    link: routes.FAQ,
    linkNew: routes.NEWFAQ,
    columns: [
      {
        name: 'title',
        label: 'Titolo',
        type: 'text',
        sort: 'asc'
      },
      {
        name: 'i18n',
        label: 'Lingue',
        type: 'langs'
      },
      {
        name: '_modified_at',
        label: 'Ultima modifica',
        type: 'date'
      },
      {
        name: '_created_at',
        label: 'Creato il',
        type: 'date',
      }
    ]
  },
  // associations list
  associations: {
    title: 'Tutte le associazioni',
    link: routes.ASSOCIATION,
    linkNew: routes.NEWASSOCIATION,
    columns: [
      {
        name: 'name',
        label: 'Nome',
        type: 'text',
        sort: 'asc'
      },
      {
        name: 'i18n',
        label: 'Lingue',
        type: 'langs'
      },
      {
        name: '_modified_at',
        label: 'Ultima modifica',
        type: 'date'
      },
      {
        name: '_created_at',
        label: 'Creato il',
        type: 'date',
      }
    ]
  },
  // schools list
  schools: {
    title: 'Tutte le scuole',
    link: routes.SCHOOL,
    linkNew: routes.NEWSCHOOL,
    columns: [
      {
        name: 'name',
        label: 'Nome',
        type: 'text',
        sort: 'asc'
      },
      {
        name: 'i18n',
        label: 'Lingue',
        type: 'langs'
      },
      {
        name: '_modified_at',
        label: 'Ultima modifica',
        type: 'date'
      },
      {
        name: '_created_at',
        label: 'Creato il',
        type: 'date',
      }
    ]
  },
  // events list
  events: {
    title: 'Tutti gli eventi',
    link: routes.EVENT,
    linkNew: routes.NEWEVENT,
    columns: [
      {
        name: 'title',
        label: 'Titolo',
        type: 'text',
        sort: 'asc'
      },
      {
        name: 'i18n',
        label: 'Lingue',
        type: 'langs'
      },
      {
        name: '_modified_at',
        label: 'Ultima modifica',
        type: 'date'
      },
      {
        name: '_created_at',
        label: 'Creato il',
        type: 'date',
      }
    ]
  },
  // attendees list
  attendees: {
    title: 'Tutti i partecipanti',
    link: routes.EVENT,
    linkNew: routes.NEWEVENT,
    columns: [
      {
        name: 'email',
        label: 'Email',
        type: 'text',
        sort: 'asc'
      },
      {
        name: 'id',
        label: 'ID',
        type: 'text'
      },
    ]
  },
}

export { lists }
