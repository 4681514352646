import { Link } from 'react-router-dom'
import { Stack, Flex, Button, Menu, MenuButton, MenuList, MenuItem, MenuDivider, } from '@chakra-ui/react'
import { useMediaQuery } from "@chakra-ui/media-query"
import { ChevronDownIcon } from '@chakra-ui/icons'

import { menus } from '../utils/menus'
import { routes } from '../utils/routes'
import Logo from './Logo'

const Header = ({ what }) => {
  const [isSmallScreen] = useMediaQuery("(max-width: 768px)")
  const style = {
    user: {
      bg: 'var(--black)',
      color: 'var(--white)'
    },
    faq: {
      bg: 'var(--green)',
      color: 'var(--white)'
    },
    event: {
      bg: 'var(--blue)',
      color: 'var(--white)'
    },
    school: {
      bg: 'var(--yellow)',
      color: 'var(--white)'
    },
    association: {
      bg: 'var(--red)',
      color: 'var(--white)'
    }
  }

  return (
    <Flex justify="space-between" align="center">
      <Link to={routes.DASHBOARD}>
        <Logo />
      </Link>
      {!isSmallScreen ?
        <Stack direction="row" spacing={5}>
          {menus.map(item =>
            <Link key={item.menu[0].url} to={item.menu[0].url}>
              <Button key={item.slug} borderRadius="lg" colorScheme={item.slug}>
                {item.title}
              </Button>
            </Link>
          )}
        </Stack>
        :
        <Menu autoSelect={false}>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Menu
          </MenuButton>
          <MenuList>
            {menus.map(item =>
              <Link key={item.menu[0].url} to={item.menu[0].url}>
                <MenuItem bg={what === item.slug ? 'gray.100' : 'white'}>
                  {item.title}
                </MenuItem>
              </Link>
            )}
          </MenuList>
        </Menu>
      }
    </Flex>
  )
}

export default Header
