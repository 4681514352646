import './App.css';
import { BrowserRouter, Switch } from 'react-router-dom'
import { AuthProvider } from './utils/authContext.js'
import { ChakraProvider, extendTheme, Flex } from '@chakra-ui/react'

import { routes } from './utils/routes'
import PrivateRoute from './components/PrivateRoute'
import PublicRoute from './components/PublicRoute'
import Footer from './components/Footer'

import Login from './page/Login'
import Admin from './page/Admin'
import Edit from './page/Edit'
import List from './page/List'
import Attendees from './page/Attendees';

function App() {
  const approdo = extendTheme({
    fonts: {
      body: "'Jost', sans-serif",
      heading: "'Jost', sans-serif",
      mono: "Menlo, monospace",
    },
    colors: {
      user: {
        50: '#F2F3F3',
        100: '#DADDDD',
        200: '#C2C7C7',
        300: '#AAB1B1',
        400: '#929B9B',
        500: '#7A8585',
        600: '#626A6A',
        700: '#495050',
        800: '#313535',
        900: '#181B1B'
      },
      faq: {
        50: '#EFF8EC',
        100: '#D2EDCA',
        200: '#B5E1A8',
        300: '#98D586',
        400: '#7ACA63',
        500: '#5DBE41',
        600: '#4B9834',
        700: '#387227',
        800: '#254C1A',
        900: '#13260D'
      },
      association: {
        50: '#FCE9EE',
        100: '#F7C0D0',
        200: '#F198B1',
        300: '#EC6F93',
        400: '#E64775',
        500: '#E11E56',
        600: '#B41845',
        700: '#871234',
        800: '#5A0C23',
        900: '#2D0611'
      },
      school: {
        50: '#FFF6E5',
        100: '#FFE6B8',
        200: '#FFD68A',
        300: '#FFC55C',
        400: '#FFB52E',
        500: '#FFA500',
        600: '#CC8400',
        700: '#996300',
        800: '#664200',
        900: '#332100'
      },
      event: {
        50: '#ECE5FF',
        100: '#C9B8FF',
        200: '#A68AFF',
        300: '#835CFF',
        400: '#612EFF',
        500: '#3E00FF',
        600: '#3200CC',
        700: '#250099',
        800: '#190066',
        900: '#0C0033'
      }
    },
  })

  return (
    <ChakraProvider theme={approdo}>
      <Flex py={6} px={[4,6]} width="100vw" minHeight="100vh" justify="center" align="flex-start">
        <AuthProvider>
          <BrowserRouter>
            <Switch>
              <PublicRoute restricted={true} component={Login} path={routes.LOGIN} exact />
              <PrivateRoute
                component={List}
                path={[
                  routes.USERS,
                  routes.FAQS,
                  routes.EVENTS,
                  routes.SCHOOLS,
                  routes.ASSOCIATIONS,
                ]} exact />
              <PrivateRoute
                component={Attendees}
                path={[
                  routes.ATTENDEES,
                ]} exact />
              <PrivateRoute
                component={Edit}
                path={[
                  `${routes.USER}/:id`,
                  `${routes.FAQ}/:id`,
                  `${routes.EVENT}/:id`,
                  `${routes.SCHOOL}/:id`,
                  `${routes.ASSOCIATION}/:id`,
                ]} exact />
              <PrivateRoute component={Admin} path={routes.DASHBOARD} exact />
              <PublicRoute restricted={true} component={Login} path={'/'} />
            </Switch>
          </BrowserRouter>
        </AuthProvider>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
