import axios from 'axios'

import {AgGridColumn, AgGridReact} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { AG_GRID_LOCALE_IT } from '../utils/aggrid.locale.it'

import { createStandaloneToast, Button } from '@chakra-ui/react'
import { DownloadIcon, EditIcon, AddIcon } from '@chakra-ui/icons'

import LanguageSelector from '../components/LanguageSelector'
import icn_0 from '../assets/icns/icn_0.svg'
import icn_1 from '../assets/icns/icn_1.svg'
import icn_2 from '../assets/icns/icn_2.svg'
import icn_3 from '../assets/icns/icn_3.svg'
import icn_4 from '../assets/icns/icn_4.svg'
import icn_5 from '../assets/icns/icn_5.svg'
import icn_6 from '../assets/icns/icn_6.svg'
import icn_7 from '../assets/icns/icn_7.svg'
import icn_8 from '../assets/icns/icn_8.svg'
import icn_9 from '../assets/icns/icn_9.svg'
import icn_10 from '../assets/icns/icn_10.svg'
import icn_11 from '../assets/icns/icn_11.svg'
import icn_12 from '../assets/icns/icn_12.svg'
import icn_13 from '../assets/icns/icn_13.svg'
import icn_14 from '../assets/icns/icn_14.svg'
import { useHistory } from 'react-router-dom'

const icons = [
  icn_0,
  icn_1,
  icn_2,
  icn_3,
  icn_4,
  icn_5,
  icn_6,
  icn_7,
  icn_8,
  icn_9,
  icn_10,
  icn_11,
  icn_12,
  icn_13,
  icn_14,
].map((icn, i) => {
  return {
    value: `icn_${i}`,
    label: <img src={icn} style={{minWidth: 40, minHeight: 40}}/>
  }
})

function getData(array) {
  const options = array.map((what) =>
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/${what.value}`)
      .then(response => {
        if (response.data.length > 0) {
          return response.data.map(item => {
            return {
              label: item.name || item.title,
              value: item.id
            }
          })
        } else {
          return []
        }
      })
    )
  return Promise.all(options)
    .then(result => {
      return result.map((res, i) => {
        return {
          label: array[i].label,
          options: result[i]
        }
      })
    })
}

const langs = [
  {
    value: 'it',
    label: 'Italiano'
  },
  {
    value: 'en',
    label: 'Inglese'
  },
  {
    value: 'es',
    label: 'Spagnolo'
  },
  {
    value: 'ar',
    label: 'Arabo'
  }
]

const user = {
  title: 'Modifica un utente',
  titleNew: 'Aggiungi un nuovo utente',
  isMulti: false,
  fields: [
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      placeholder: 'Inserisci un indirizzo mail valido...',
      rules: [
        {
          required: true,
          message: 'Devi inserire un indirizzo email!',
        }
      ]
    },
    {
      name: 'password',
      label: 'Nuova password',
      placeholder: 'Inserisci almeno 6 caratteri...',
      type: 'password',
      show: false
    },
    {
      name: 'remember_password',
      label: 'Ricorda password',
      hint: 'Invia una mail all\'utente per consentire il ripristino della password.',
      type: 'button',
      cta: 'Invia',
      action: (event, id, values) => {
        event.preventDefault()

        const toast = createStandaloneToast()
        console.log('action!')
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/app/public/forgot/request`, { email: values.email })
          .then(response => {
            console.log('send-verify', response.data);

            toast({
              title: response.data ? 'Successo' : 'Errore',
              description: response.data ? 'Email inviata correttamente' : 'Email non inviata',
              variant: 'solid',
              status: response.data ? 'success' : 'error',
              isClosable: true,
            })

          })
          .catch(function (error) {
            console.log(error);
            toast({
              title: 'Errore di sistema!',
              description: 'Qualcosa è andato storto.',
              variant: 'solid',
              status: 'error',
              isClosable: true,
            })
          })
      },
      hiddenIfNew: true,
    },
    {
      name: 'emailVerified',
      label: 'Imposta l\'utente come "verificato"',
      hint: 'L\'utente non avrà bisogno di verificare il proprio indirizzo email.',
      type: 'button',
      cta: 'Verifica',
      action: (event, id, values) => {
        event.preventDefault()

        const toast = createStandaloneToast()
        console.log('action!')
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user/${id}/verify`)
          .then(response => {
            console.log('verify', response.data);

            toast({
              title: response.data[1][0],
              description: response.data[1][1],
              variant: 'solid',
              status: response.data[0] ? 'success' : 'error',
              isClosable: true,
            })

            if (response.data[0]) {
              window.location.reload(false)
            }

          })
          .catch(function (error) {
            console.log(error);
            toast({
              title: 'Errore di sistema!',
              description: 'Qualcosa è andato storto.',
              variant: 'solid',
              status: 'error',
              isClosable: true,
            })
          })
      },
      hiddenIfTrue: true,
      hiddenIfNew: true,
    },
    {
      name: 'i18n',
      label: 'Lingua',
      placeholder: 'Scegli la lingua...',
      hint: 'Imposta la lingua di visualizzazione.',
      type: 'select',
      options: langs
    },
    {
      name: 'consent',
      label: 'Consenso privacy',
      type: 'checkbox',
      cta: 'L\'utente dichiara di aver letto e accettato l\'informativa sulla privacy.',
      rules: [
        {
          validator: (_, value) =>
            value ? Promise.resolve() : Promise.reject(new Error('L\'utente dovrebbe accettare le condizioni!')),
        },
      ]
    },
    {
      name: 'favorites',
      label: 'Associazioni, scuole o eventi preferiti',
      type: 'searchable',
      placeholder: 'Seleziona associazioni o scuole...',
      multiple: true,
      options: [],
      getOptions: () => getData([{
        value: 'associations',
        label: 'Associazioni',
      }, {
        value: 'schools',
        label: 'Scuole',
      }, {
        value: 'events',
        label: 'Eventi',
      }])
    },
  ]
}

const exp = {
  title: 'Modifica un evento',
  titleNew: 'Aggiungi un nuovo evento',
  isMulti: true,
  fields: [
    {
      name: 'title',
      label: 'Titolo',
      type: 'text',
      placeholder: 'Inserisci un titolo...'
    },
    {
      name: 'date_start',
      label: 'Quando?',
      type: 'date',
    },
    {
      name: 'duration',
      label: 'Durata',
      type: 'slider',
      min: 0,
      max: 240,
      step: 15,
      hint: 'Inserisci la durata in minuti. Puoi inserire 0 per non mostrare questo campo.'
    },
    {
      name: 'info',
      label: 'Informazioni',
      type: 'textarea',
      placeholder: 'Descrivi brevemente il programma dell\'evento...'
    },
    {
      name: 'icons',
      label: 'Icone',
      type: 'choices',
      max: 4,
      options: icons,
      hint: 'Puoi selezionare un massimo di 3 icone.'
    },
    {
      name: 'type',
      label: 'Tipologia',
      type: 'select',
      placeholder: 'Seleziona una tipologia...',
      options: [
        {
          value: '1',
          label: 'Laboratorio'
        },
        {
          value: '2',
          label: 'Lezione'
        },
        {
          value: '3',
          label: 'Conferenza'
        },
        {
          value: '4',
          label: 'Inizio anno scolastico'
        },
        {
          value: '5',
          label: 'Inizio anno accademico'
        },
      ]
    },
    {
      name: 'i18n',
      label: 'Lingue',
      type: 'choices',
      options: langs.map(lang => {
        return {
          value: lang.value,
          label: <LanguageSelector lang={lang.value} showCountry={true} size="md" />
        }
      }),
      hint: 'Seleziona le lingue in cui sarà possibile seguire l\'evento.'
    },
    {
      name: 'host',
      label: 'Associazioni o scuole organizzatrici',
      type: 'searchable',
      placeholder: 'Seleziona associazioni o scuole...',
      multiple: true,
      hint: 'Se l\'evento non è organizzato da scuole o associazioni inserite in Approdo lasciare vuoto.',
      options: [],
      getOptions: () => getData([{
        value: 'associations',
        label: 'Associazioni',
      }, {
        value: 'schools',
        label: 'Scuole',
      }])
    },
    {
      name: 'location',
      label: 'Nome location',
      type: 'text',
      placeholder: 'Inserisci il nome della location dove si terrà l\'evento...'
    },
    {
      name: 'address',
      label: 'Indirizzo',
      type: 'geo',
      placeholder: 'Inserisci un indirizzo per la location...'
    },
    {
      name: 'seats',
      label: 'Posti disponibili',
      type: 'slider',
      defaultValue: 0,
      min: 0,
      max: 1000,
      step: 5,
      hint: 'Seleziona 0 per non mostrare questo campo. Impostare 0 posti disponibili renderà impossibile la registrazione degli utenti all\'evento.'
    },
    {
      name: 'cost',
      label: 'Costo',
      type: 'money',
      placeholder: 'Inserisci il costo di partecipazione...',
    },
    {
      name: 'contacts',
      label: 'Contatti',
      type: 'array',
      array: [
        {
          name: 'contact_name',
          label: 'Nome',
          type: 'text',
          placeholder: 'Inserisci un nome...',
          isTitle: true,
        },
        {
          name: 'contact_tel',
          label: 'Telefono',
          type: 'text',
          placeholder: 'Inserisci un numero di telefono...',
        },
        {
          name: 'contact_email',
          label: 'Email',
          type: 'email',
          placeholder: 'Inserisci un indirizzo email...',
        }
      ]
    },
    {
      name: 'website',
      label: 'Sito web',
      placeholder: 'Inserisci l\'indirizzo del sito web...',
      type: 'url',
    },
    {
      name: 'materials',
      label: 'Materiali scaricabili',
      placeholder: 'Inserisci l\'indirizzo del sito web...',
      type: 'files',
    },
    {
      name: 'attendees',
      label: 'Partecipanti',
      type: 'button',
      cta: 'Gestisci i partecipanti',
      action: () => {
        window.location.href = `${window.location.pathname}/attendees`
      },
      hiddenIfNew: true,
    },
  ]
}

const faq = {
  title: 'Modifica un materiale informativo',
  titleNew: 'Aggiungi un materiale informativo',
  isMulti: true,
  fields: [
    {
      name: 'title',
      label: 'Titolo',
      type: 'text',
      placeholder: 'Inserisci un titolo...'
    },
    {
      name: 'icon',
      label: 'Icona',
      type: 'choices',
      max: 1,
      options: icons,
      hint: 'Puoi selezionare una sola icona',
    },
    {
      name: 'elements',
      label: 'Materiali',
      type: 'array',
      array: [
        {
          name: 'icon',
          label: 'Icona',
          type: 'choices',
          max: 1,
          options: icons,
          hint: 'Icona presente nella sezione interna del materiale informativo.'
        },
        {
          name: 'title',
          label: 'Titolo/domanda',
          type: 'text',
          placeholder: 'Inserisci un titolo...',
          hint: 'A seconda del layout scelto questo campo sarà il titolo della sezione interna del materiale informativo o la domanda.',
          isTitle: true,
        },
        {
          name: 'text',
          label: 'Contenuto/risposta',
          type: 'textarea',
          placeholder: 'Inserisci il testo...',
          hint: 'A seconda del layout scelto questo campo sarà il corpo della sezione interna del materiale informativo o la risposta.'
        },
        {
          name: 'button_text',
          label: 'Testo del pulsante',
          type: 'text',
          placeholder: 'Inserisci il nome di cosa stai linkando...',
          isTitle: true,
        },
        {
          name: 'button_url',
          label: 'Indirizzo web',
          type: 'url',
          placeholder: 'Inserisci un url...',
        },
      ]
    },
    {
      name: 'onInit',
      label: 'Splash screen',
      cta: 'Materiale visualizzato all\'avvio dell\'app',
      placeholder: 'Scegli l\'impaginazione di questo materiale...',
      type: 'checkbox',
      hint: 'Selezionando questa opzione, il materiale sarà presentato all\'avvio dell\'app.'
    },
    {
      name: 'order',
      label: 'Ordine (priorità di visualizzazione)',
      type: 'slider',
      hint: 'Più è alto il numero, prima verrà visualizzato il materiale (sia nell\'area dedicata, sia nello splash screen).',
      min: 1,
      max: 10,
      default: 1
    },

  ]
}

const association = {
  title: 'Modifica un\'associazione',
  titleNew: 'Aggiungi un\'associazione',
  isMulti: true,
  fields: [
    {
      name: 'name',
      label: 'Nome',
      type: 'text',
      placeholder: 'Inserisci un nome...'
    },
    {
      name: 'info',
      label: 'Informazioni',
      type: 'textarea',
      placeholder: 'Descrivi brevemente l\'associazione...'
    },
    {
      name: 'icons',
      label: 'Icone',
      type: 'choices',
      max: 4,
      options: icons,
      hint: 'Puoi selezionare un massimo di 3 icone.'
    },
    {
      name: 'type',
      label: 'Tipologia',
      type: 'select',
      placeholder: 'Seleziona una tipologia...',
      options: [
        {
          value: '1',
          label: 'Associazione culturale ARCI'
        },
        {
          value: '2',
          label: 'Assistenziale'
        },
      ]
    },
    {
      name: 'address',
      label: 'Indirizzo',
      type: 'geo',
      placeholder: 'Cerca l\'indirizzo dell\'associazione...'
    },
    {
      name: 'contacts',
      label: 'Contatti',
      type: 'array',
      array: [
        {
          name: 'contact_name',
          label: 'Nome',
          type: 'text',
          placeholder: 'Inserisci un nome...',
          isTitle: true,
        },
        {
          name: 'contact_tel',
          label: 'Telefono',
          type: 'text',
          placeholder: 'Inserisci un numero di telefono...',
        },
        {
          name: 'contact_email',
          label: 'Email',
          type: 'email',
          placeholder: 'Inserisci un indirizzo email...',
        }
      ]
    },
    {
      name: 'website',
      label: 'Sito web',
      placeholder: 'Inserisci l\'indirizzo del sito web...',
      type: 'url',
    },
  ]
}

const school = {
  title: 'Modifica la scuola',
  titleNew: 'Aggiungi una scuola',
  isMulti: true,
  fields: [
    {
      name: 'name',
      label: 'Nome',
      type: 'text',
      placeholder: 'Inserisci un nome...'
    },
    {
      name: 'type',
      label: 'Tipologia',
      type: 'select',
      placeholder: 'Seleziona una tipologia...',
      options: [
        {
          value: '1',
          label: 'Scuola dell\'infanzia (Materna)'
        },
        {
          value: '2',
          label: 'Scuola primaria (Elementare)'
        },
        {
          value: '3',
          label: 'Scuola secondaria di primo grado (Media)'
        },
        {
          value: '4',
          label: 'Scuola secondaria di secondo grado (Superiore)'
        },
        {
          value: '6',
          label: 'Università'
        },
        {
          value: '7',
          label: 'Collegio studentesco'
        },
        {
          value: '8',
          label: 'Corsi professionalizzanti'
        },
        {
          value: '9',
          label: 'Ente di formazione'
        },
        {
          value: '10',
          label: 'Istituto tecnico superiore (ITS)'
        },
      ]
    },
    {
      name: 'studyCourse',
      label: 'Corsi di studio',
      type: 'searchable',
      placeholder: 'Seleziona i corsi di studio disponibili...',
      multiple: true,
      options: [{
          value: '2',
          label: 'Biennio'
        }, {
          value: '3',
          label: 'Triennio'
        }, {
          value: '5',
          label: 'Quinquennio'
        }, {
          value: '2+3',
          label: 'Biennio + Triennio'
        }, {
          value: '3+2',
          label: 'Triennio + Biennio'
        }],
    },
    {
      name: 'info',
      label: 'Informazioni',
      type: 'textarea',
      placeholder: 'Descrivi brevemente la scuola...'
    },
    {
      name: 'toUni',
      label: 'Accesso all\'università',
      type: 'textarea',
      placeholder: 'Inserisci un breve appunto sull\'università...'
    },
    {
      name: 'toJob',
      label: 'Sbocchi professionali',
      type: 'textarea',
      placeholder: 'Inserisci un breve appunto sugli sbocchi professionali...'
    },
    {
      name: 'icnStudy',
      label: 'Quanto studio richiede?',
      type: 'slider',
      min: 1,
      max: 3,
      hint: 'Seleziona un valore da 1 a 3.',
    },
    {
      name: 'icnJob',
      label: 'Quanto avvia al lavoro?',
      type: 'slider',
      min: 1,
      max: 3,
      hint: 'Seleziona un valore da 1 a 3.',
    },
    {
      name: 'icnLab',
      label: 'Quanto è laboratoriale?',
      type: 'slider',
      min: 1,
      max: 3,
      hint: 'Seleziona un valore da 1 a 3.',
    },
    {
      name: 'address',
      label: 'Indirizzo',
      type: 'geo',
      placeholder: 'Cerca l\'indirizzo della scuola...'
    },
    {
      name: 'contacts',
      label: 'Contatti',
      type: 'array',
      array: [
        {
          name: 'contact_name',
          label: 'Nome',
          type: 'text',
          placeholder: 'Inserisci un nome...',
          isTitle: true,
        },
        {
          name: 'contact_tel',
          label: 'Telefono',
          type: 'text',
          placeholder: 'Inserisci un numero di telefono...',
        },
        {
          name: 'contact_email',
          label: 'Email',
          type: 'email',
          placeholder: 'Inserisci un indirizzo email...',
        }
      ]
    },
    {
      name: 'website',
      label: 'Sito web',
      placeholder: 'Inserisci l\'indirizzo del sito web...',
      type: 'url',
    },
    {
      name: 'links',
      label: 'Link utili',
      type: 'array',
      array: [
        {
          name: 'what',
          label: 'Nome',
          type: 'text',
          placeholder: 'Inserisci il nome di cosa stai linkando...',
          isTitle: true,
        },
        {
          name: 'url',
          label: 'Indirizzo web',
          type: 'url',
          placeholder: 'Inserisci un url...',
        },
      ]
    },
    {
      name: 'materials',
      label: 'Materiali scaricabili',
      placeholder: 'Inserisci l\'indirizzo del sito web...',
      type: 'files',
    },
  ]
}

export { langs, user, exp, faq, association, school }
