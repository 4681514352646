import { Stack } from '@chakra-ui/react'

import Logo from '../components/Logo'
import LoginForm from '../components/LoginForm'
import Footer from '../components/Footer'


const Login = () => (
    <Stack direction="column" alignSelf="center" spacing={10}>
      <Logo />
      <LoginForm />
      <Footer />
    </Stack>
)
export default Login
