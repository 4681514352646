const routes = {
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  USERS: '/dashboard/users',
  USER: '/dashboard/user',
  FAQS: '/dashboard/faqs',
  FAQ: '/dashboard/faq',
  EVENTS: '/dashboard/events',
  EVENT: '/dashboard/event',
  ATTENDEES: '/dashboard/event/:id/attendees',
  SCHOOLS: '/dashboard/schools',
  SCHOOL: '/dashboard/school',
  ASSOCIATIONS: '/dashboard/associations',
  ASSOCIATION: '/dashboard/association',
  NEWUSER: '/dashboard/user/new',
  NEWFAQ: '/dashboard/faq/new',
  NEWEVENT: '/dashboard/event/new',
  NEWSCHOOL: '/dashboard/school/new',
  NEWASSOCIATION: '/dashboard/association/new'
}

export { routes }
