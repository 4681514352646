import { Box, Wrap, useCheckbox, useCheckboxGroup } from '@chakra-ui/react'

const Choices = ({ field, item, helpers }) => {
  const { getCheckboxProps } = useCheckboxGroup({
    ...field,
    defaultValue: field.value || [],
  })
  return (
      <Wrap spacing={4}>
        {item.options.map((el) => {

          const checkbox = getCheckboxProps({ value: el.value })
          return (
            <Choice
              key={el.value}
              {...checkbox}
              onChange={(event) => {
                if (field.value && field.value.length >= item.max && event.target.checked) return

                let newValue
                newValue = (event.target.checked) ?
                  (field.value) ? [...field.value, el.value] : [el.value]
                  :
                  field.value.filter(i => i !== el.value)

                helpers.setFieldValue(field.name, newValue)
              }}
              >
              {el.label}
            </Choice>
          )
        })}
      </Wrap>
  )
}

const Choice = (props) => {
  const { getInputProps, getCheckboxProps } = useCheckbox(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="none"
        _checked={{
          bg: "gray.300",
          borderColor: "gray.300",
        }}
        _focus={{
          boxShadow: "none",
        }}
        px={4}
        py={2}
      >
        {props.children}
      </Box>
    </Box>
  )
}

export default Choices
