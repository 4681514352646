import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import {
  Stack,
  useToast,
  Button,
  Center,
  Badge,
  Heading
} from '@chakra-ui/react'
import { DownloadIcon, CloseIcon, AddIcon } from '@chakra-ui/icons'
import {AgGridColumn, AgGridReact} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import LanguageSelector from '../components/LanguageSelector'
import moment from 'moment'
import { useAuthContext } from '../utils/authContext.js'


import { lists } from '../utils/lists'
import { AG_GRID_LOCALE_IT } from '../utils/aggrid.locale.it'
import Header from '../components/Header'
import { useParams } from 'react-router-dom'

const Attendees = () => {
  let { id } = useParams()
  let location = useLocation()
  const toast = useToast()
  moment.locale('it')
  const { logout } = useAuthContext()

  const [ what, setWhat ] = useState(location.pathname.split('/').pop())
  const [ content, setContent ] = useState(lists[location.pathname.split('/').pop()])
  const [ list, setList ] = useState([])
  const [ gridApi, setGridApi ] = useState(null)

  useEffect(() => {
    setWhat(location.pathname.split('/').pop())
    setContent(lists[location.pathname.split('/').pop()])

    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/event/${id}/attendees`)
      .then(response => {
        console.log('attendees', response.data)
        if (response.data.length > 0) {
          setList(response.data)
        } else {
          setList([])
          toast({
            title: 'Nessun partecipante!',
            description: 'Ancora nessuno parteciperà a questo evento',
            variant: 'solid',
            status: 'warning',
            isClosable: true,
          })
        }
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 401) logout()
      })
  }, [location.pathname])

  const onGridReady = (params) => {
    setGridApi(params.api)
  }
  const onExport = () => {
    gridApi.exportDataAsCsv({
      fileName: `${what}_${moment().format('YYYYMMDD_HHmmss')}`,
      processHeaderCallback: (params) => {
        console.log(params.column.userProvidedColDef.headerName)
        if (params.column.userProvidedColDef.headerName === 'Azioni') {
          return 'ID'
        } else {
          return params.column.userProvidedColDef.headerName
        }
      }
    })
  }

  // generic sorter
  const sorter = (valueA, valueB, nodeA, nodeB, isInverted) => {
      if (valueA === valueB) return 0
      return (valueA > valueB) ? 1 : -1
  }
  const dateSorter = (valueA, valueB, nodeA, nodeB, isInverted) => {
      if (moment(valueA).format('YYYYMMDD_HHmmss') === moment(valueB).format('YYYYMMDD_HHmmss')) return 0
      return (moment(valueA).format('YYYYMMDD_HHmmss') > moment(valueB).format('YYYYMMDD_HHmmss')) ? 1 : -1
  }

  const ActionsRenderer = (params) => {
    const action = () => {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/event/${id}/attendee/${params.data.id}/remove`)
      .then(response => {
        console.log('attendees', response.data)
        if (response.data) {
          setList(state => state.filter(e => e.id !== params.data.id))
          toast({
            title: 'Operazione riuscita!',
            description: 'L\'utente è stato rimosso dalla lista dei partecipanti.',
            variant: 'solid',
            status: 'success',
            isClosable: true,
          })
        } else {
          toast({
            title: 'Errore!',
            description: 'Non è stato possibile rimuovere l\'utente dalla lista dei partecipanti.',
            variant: 'solid',
            status: 'error',
            isClosable: true,
          })
        }
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 401) logout()
      })
    }
    return (
      <Button leftIcon={<CloseIcon />} size="xs" mb={1} colorScheme="red" onClick={() => action()}>
        Rimuovi
      </Button>
    )
  }
  const CellRenderer = (params) => {
    // se non c'è niente da mostrare nella cella
    if (!params.value && params.value !== false) {
      return 'Utente eliminato'

    // se è una data
    } else if (params.columnInfo.type === 'date') {
    //  console.log(params.value)
      const timestamp = (typeof params.value === 'string') ? moment(params.value) : moment.unix(params.value._seconds)

      return timestamp.format('DD-MM-YYYY HH:mm:ss')

    // se è vero/falso
    } else if (params.columnInfo.type === 'bool') {
      return (
        <Center h="35px" style={{lineHeight: '1em'}}>
          {(params.value) ?
            <Badge colorScheme="green" pt={.5}>Sì</Badge>
            :
            <Badge colorScheme="red" pt={.5}>No</Badge>
          }
        </Center>
      )

      // se sono i linguaggi usati
    } else if (params.columnInfo.type === 'langs') {
      console.log(params.value)
      let aValue
      if (typeof params.value === 'string') {
        aValue = [ params.value ]
      } else {
        aValue = params.value
      }
      return (
        <Center h="35px" style={{lineHeight: '1em'}}>
          <Stack direction="row" spacing={2}>
            {aValue.map(lang => <LanguageSelector key={lang} lang={lang} showCountry={false} size="sm" />)}
          </Stack>
        </Center>
      )


    // se è testo
    } else {
      return params.value
    }

  }

  return(
    <Stack key={what} direction="column" width={["100%", "80%"]}>
      <Header what={what.slice(0, -1)} />
      <div className="content">
        <Stack direction={["column", "row"]} justify="space-between" align="center">
          {content &&
            <Heading as="h1" align="center" pt={5} pb={[2, 10]}>{content.title}</Heading>
          }
          <Stack direction="row" justify="flex-end" mt={2} pb={10}>
            {false &&
              <Link to={content.linkNew}>
                <Button leftIcon={<AddIcon />}>
                  Aggiungi nuovo
                </Button>
              </Link>
            }
            {false &&
              <Button leftIcon={<DownloadIcon />} onClick={() => onExport()}>
              Scarica in formato CSV
            </Button>}
          </Stack>
        </Stack>
        <div className="ag-theme-alpine" style={{height: 1100, width: '100%'}}>
          <AgGridReact
            onGridReady={onGridReady}
            localeText={AG_GRID_LOCALE_IT}
            rowData={list}
            pagination={true}
            paginationPageSize={25}
            animateRows={true}
            defaultColDef={{
              sortable: true,
              flex: 1,
              minWidth: 100,
              filter: true,
              resizable: true,
            }}
            statusBar={{
              statusPanels: [
                {
                  statusPanel: 'agTotalAndFilteredRowCountComponent',
                  align: 'left',
                }
              ]
            }}>
            {content.columns.map(column =>
              <AgGridColumn
                key={column.name}
                field={column.name}
                headerName={column.label}
                sort={column.sort || null}
                comparator={(column.type === 'date') ? dateSorter : sorter}
                cellRendererFramework={CellRenderer}
                cellRendererParams={{
                  columnInfo: column
                }}>
              </AgGridColumn>
            )}
            <AgGridColumn
              field="id"
              headerName="Azioni"
              cellRendererFramework={ActionsRenderer}
              cellRendererParams={{
                link: content.link
              }}
              sortable={false}
              filter={false}
              flex={.8}>
            </AgGridColumn>
          </AgGridReact>
        </div>
      </div>
    </Stack>
  )
}



export default Attendees
