import { useState, useEffect } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Button} from '@chakra-ui/react'

const Popup = ({ field, item, helpers }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
     <Button onClick={onOpen}>{item.cta}</Button>

     <Modal onClose={onClose} isOpen={isOpen} isCentered>
       <ModalOverlay />
       <ModalContent>
         <ModalHeader>{item.header}</ModalHeader>
         <ModalCloseButton />
         <ModalBody>
           {item.body(helpers.values.id || undefined)}
         </ModalBody>
         <ModalFooter>
           <Button onClick={onClose}>Chiudi</Button>
         </ModalFooter>
       </ModalContent>
     </Modal>
   </>
  )
}

export default Popup
