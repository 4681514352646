
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuthContext } from '../utils/authContext.js'
import { routes } from '../utils/routes.js'

const PrivateRoute = ({component: Component, ...rest}) => {
  const { isAuth } = useAuthContext()

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      isAuth ?
        <Component {...props} />
        :
        <Redirect to={routes.LOGIN} />
    )} />
  );
};

export default PrivateRoute;
