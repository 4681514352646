import { useState } from 'react'
import { Center, Stack, Box, Heading, Text, IconButton, Progress } from '@chakra-ui/react'
import { DeleteIcon, ArrowDownIcon } from '@chakra-ui/icons'
import Dropzone from 'react-dropzone'
import FormData from 'form-data'
import axios from 'axios'
import { saveAs } from 'file-saver'

const Files = ({ field, item, helpers }) => {
  const [isUploading, setUploading] = useState(false)

  const uploadIt = (acceptedFiles) => {
    setUploading(true)

    console.log(acceptedFiles)
    const formData = new FormData()
    acceptedFiles.map((acceptedFile, i) => {
      formData.append(`file-${i}`, acceptedFile);
    })
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/files/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: ProgressEvent => {
            console.log(Math.round(
                (ProgressEvent.loaded * 100) / ProgressEvent.total
              )
            )
          }
        })
      .then(response => {
        console.log(response)
        const allFiles = (field.value) ? field.value.concat(response.data) : response.data

        helpers.setFieldValue(field.name, allFiles)
        setUploading(false)
      })
  }

  const deleteIt = (file) => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/files/${file}`)
      .then(response => {
        if (response.data) {
          const allFiles = field.value.filter(el => el !== file)
          console.log(allFiles)
          helpers.setFieldValue(field.name, allFiles)
        }
      })
  }

  const downloadIt = (file) => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/files/${file}`,
        )
      .then(response => {
        if (response.data) {
          console.log(response.data)
          saveAs(response.data, file.split('___')[1])
        }
      })
  }

  return (
    <Dropzone onDrop={uploadIt}>
      {({acceptedFiles, getRootProps, getInputProps}) => {

        return(
          <>
          {isUploading &&
            <Box py={8}>
              <Progress my={2} size="xs" isIndeterminate />
            </Box>
          }
          {!isUploading &&
            <Center {...getRootProps()} p={6} borderWidth={4} borderRadius="lg" borderStyle="dashed">
              <input {...getInputProps()} />
                <Text>Trascina i file qui oppure clicca e selezionali</Text>
            </Center>
          }
            <Box mt={3}>
              {field.value && field.value.length > 0 &&
                <>
                  <Heading as="h4" size="sm">File caricati:</Heading>
                  <Stack direction="column" spacing={2} mt={2}>
                    {field.value.map((file, i) => (
                      <Stack direction="row" bg={i % 2 === 0 ? 'gray.100' : 'white'} justify="space-between" align="center" key={file} py={2} px={4} borderRadius="lg">
                        <Text>{file.split('___')[1]}</Text>
                        <Stack direction="row" spacing={2}>
                          <IconButton
                            colorScheme="yellow"
                            aria-label={`Scarica ${item.name.toLowerCase()}`}
                            icon={<ArrowDownIcon />}
                            size="sm"
                            onClick={() => downloadIt(file)}
                          />
                          <IconButton
                            colorScheme="red"
                            aria-label={`Elimina ${item.name.toLowerCase()}`}
                            icon={<DeleteIcon />}
                            size="sm"
                            onClick={() => deleteIt(file)}
                          />
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </>
              }
            </Box>
          </>
        )}}
      </Dropzone>
  )
}

export default Files
