import './Logo.css'

export default function Logo() {
  // viewBox="0 0 129.5 216.1"
  return (
    <div className="the-logo">
      <h1>
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 129.5 130">
          <path d="M129.5,64.7C129.5,29,100.5,0,64.7,0C29,0,0,29,0,64.7c0,33.2,25,60.6,57.2,64.3v22.5c-1.1,0.4-2.2,0.8-3.3,1.3 c-9.9,5-14.5,16.7-10.7,27.3l10.3,28.6c1.6,4.5,5.8,7.4,10.6,7.4c0,0,0.1,0,0.1,0c4.7-0.1,8.9-3.1,10.5-7.5l10.2-28.4 c3.8-10.6-0.8-22.3-10.7-27.3c-0.7-0.3-1.4-0.6-2.1-0.9V129C104.4,125.3,129.5,97.9,129.5,64.7z M19.6,64.7 c0-24.9,20.2-45.1,45.1-45.1s45.1,20.2,45.1,45.1c0,22.3-16.3,40.9-37.6,44.5v-25c7.8-3,13.4-10.6,13.4-19.5 c0-11.5-9.3-20.9-20.9-20.9s-20.9,9.3-20.9,20.9c0,8.9,5.6,16.5,13.4,19.5v25C35.9,105.6,19.6,87,19.6,64.7z M74.4,176.3l-10.3,28.5 l0,0l-10.3-28.6c-1.8-5.1,0.3-11,5.1-13.5c1.5-0.8,3.3-1.2,5.1-1.2c1.8,0,3.6,0.4,5.1,1.2C74.1,165.3,76.2,171.2,74.4,176.3z" />
        </svg>
        approdo
      </h1>
    </div>
  )
}
