import Logo from '../components/Logo'
import Footer from '../components/Footer'
import { Link, useLocation } from 'react-router-dom'
import { Stack, Button, Center, Divider, Box } from '@chakra-ui/react'
import { useAuthContext } from '../utils/authContext.js'
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'

import { menus } from '../utils/menus'


const Admin = () => {
  const { logout } = useAuthContext()

  return(
    <Stack direction="column" align="center" spacing={10} alignSelf="center">
      <Logo />
      <Stack direction="column" align="stretch" spacing={5}>
        <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" p={5}>
          <Stack direction="column" align="stretch" spacing={5}>
            {menus.map(item =>
              <Link key={item.slug} to={item.menu[0].url} style={{flex: '1 0 100%' }}>
                <Button rightIcon={<ArrowForwardIcon />} colorScheme={item.slug} width="100%">
                  {item.title}
                </Button>
              </Link>
            )}
          </Stack>
        </Box>
        <Button
          onClick={logout}
          colorScheme="red"
          variant="outline"
          leftIcon={<ArrowBackIcon />}
          >
          Esci
        </Button>
      </Stack>
      <Footer />
    </Stack>
  )
}

export default Admin
