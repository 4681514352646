import { GoogleLogin } from 'react-google-login'
import axios from 'axios'
import { Center } from '@chakra-ui/react'

import { useAuthContext } from '../utils/authContext.js'

export default function LoginForm() {
  const { login } = useAuthContext()

  const responseGoogle = (googleResponse) => {
    console.log('res', googleResponse)
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/login-admin`, {tokenId: googleResponse.tokenId})
    .then(response => {
      console.log('login-admin', response.data);
      if (response.data.accessGranted) {
        login(response.data)
      }

      // GESTIONE ERRORI LOGIN: FORBIDDEN, INVALID

    })
    .catch(function (error) {
      console.log(error)
    })
  }

  return (
    <Center>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText="Entra con le tue credenziali Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
      />
    </Center>
  )
}
