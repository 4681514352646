import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { Formik, Form } from 'formik'
import {
  Stack,
  Button,
  useToast,
  Skeleton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Tabs, TabList, TabPanels, Tab, TabPanel,
} from '@chakra-ui/react'
import { DeleteIcon, LockIcon } from '@chakra-ui/icons'
import LanguageSelector from './LanguageSelector'
import TheFields from './TheFields'
import { useHistory } from 'react-router-dom'
import { langs } from '../utils/fields'
import { useAuthContext } from '../utils/authContext.js'

const TheForm = ({ isMulti, rawFields, what }) => {
  const { logout } = useAuthContext()
  let history = useHistory()
  const toast = useToast()
  const [ isLoading, setLoading ] = useState(true)
  const [ isDeleting, setDeleting ] = useState(false)
  const [ isAlert, setAlert ] = useState(false)
  const [ fields, setFields ] = useState(rawFields)
  const [ initialValues, setInitialValues ] = useState({})

  const cancelAlert = useRef()

  useEffect(() => {
    getValues()
  }, [])

  const getValues = () => {
    if (what.id !== 'new') {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/${what.what}/${what.id}`)
        .then(response => {
          if (response.data[0]) {
            setInitialValues(response.data[1])
            setLoading(false)
            console.log('old-values', response.data[1])
          } else {
            toast({
              title: response.data[1][0],
              description: response.data[1][1],
              variant: 'solid',
              status: 'error',
              isClosable: true,
            })
            setLoading(false)
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 401) logout()
        })
    } else {
      console.log('old-values', 'none')
      setLoading(false)
    }
  }

  const sendForm = ( values, actions ) => {

    console.log('form-sent:', values)

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/${what.what}/${what.id}`, values)
      .then(response => {
        console.log('edit', response.data);

        toast({
          title: response.data[1][0],
          description: response.data[1][1],
          variant: 'solid',
          status: response.data[0] ? 'success' : 'error',
          isClosable: true,
        })

        actions.setSubmitting(false)

        if (response.data[0] && what.id === 'new') {
          history.push(`/admin/${what.what}/${response.data[2]}`)
        }

      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) logout()
        toast({
          title: 'Errore di sistema!',
          description: 'Compila tutti i campi e riprova.',
          variant: 'solid',
          status: 'error',
          isClosable: true,
        })
        actions.setSubmitting(false)
      });

  }

  const onDelete = (e) => {
    e.preventDefault()
    setAlert(true)
    setDeleting(true)
  }

  const onNotDelete = (e) => {
    e.preventDefault()
    setAlert(false)
    setDeleting(false)
  }

  const sendDelete = (e) => {
    e.preventDefault()

    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/${what.what}/${what.id}`, {})
      .then(response => {
        console.log('edit', response.data);

        toast({
          title: response.data[1][0],
          description: response.data[1][1],
          variant: 'solid',
          status: response.data[0] ? 'success' : 'error',
          isClosable: true,
        })

        setAlert(false)
        setDeleting(false)

        if (response.data[0]) {
          history.push(`/admin/${what.what}s`)
        }

      })
      .catch(function (error) {
        console.log(error);
        toast({
          title: 'Errore di sistema!',
          description: 'Contatta un amministratore o riprova.',
          variant: 'solid',
          status: 'error',
          isClosable: true,
        })
      });

  }

  return (
    <>
      {isLoading && <Skeleton></Skeleton>}
      {!isLoading && fields &&
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => sendForm(values, actions)}>
            {props => (
              <Form>
                <Tabs isFitted>
                  <TabList >
                    {langs.map(lang =>
                      <Tab key={lang.value} isDisabled={!isMulti && lang.value !== 'it'}>
                        <LanguageSelector lang={lang.value} showCountry={true} size="md" />
                      </Tab>
                    )}
                  </TabList>
                  <TabPanels>
                    {langs.map(lang =>
                      <TabPanel key={lang.value} py={14}>
                        <TheFields lang={(isMulti) ? lang.value : false} fields={fields} setFields={setFields} props={props} what={what} />
                      </TabPanel>
                    )}
                  </TabPanels>
                </Tabs>
                <Stack direction="row" justify={what.id === 'new' ? 'flex-end' : 'space-between'} spacing={10}>
                  {what.id !== 'new' &&
                    <Button
                      colorScheme="red"
                      isLoading={isDeleting}
                      loadingText={'Sto eliminando...'}
                      variant="outline"
                      leftIcon={<DeleteIcon />}
                      onClick={(e) => onDelete(e)}
                      >
                      Elimina
                    </Button>
                  }
                  <Button
                    colorScheme="green"
                    type="submit"
                    isLoading={props.isSubmitting}
                    loadingText={'Sto salvando...'}
                    leftIcon={<LockIcon />}
                    isDisabled={!props.dirty}
                    >
                    Salva
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>



        <AlertDialog
          isOpen={isAlert}
          leastDestructiveRef={cancelAlert}
          onClose={onNotDelete}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Richiesta di eliminazione
              </AlertDialogHeader>

              <AlertDialogBody>
                Dopo l'eliminazione, l'elemento non potrà essere ripristinato.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelAlert} onClick={onNotDelete}>
                  Non eliminare
                </Button>
                <Button colorScheme="red" onClick={sendDelete} ml={3}>
                  Elimina
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        </>
        }
      </>
  )
}
export default TheForm
