import { useState, useEffect } from 'react'
import Select from 'react-select'
import { Flex, Text, Badge } from '@chakra-ui/react'

const Searchable = ({ field, item, helpers }) => {
  const [isInit, setInit] = useState(false)
  const [options, setOptions] = useState([])
  const [values, setValues] = useState([])

  const formatGroupLabel = data => (
    <Flex justify="space-between" align="center">
      <span>{data.label}</span>
      <Badge>{data.options.length}</Badge>
    </Flex>
  );

  const getValues = (options) => {
//    console.log('options', options)
//    console.log('values-pre', values)
//    console.log('field.value', field.value)
    if (!field.value || !field.value[0]) {
      return
    }

    setValues(
      (options[0].options) ?
        options.flatMap(it => {
          return it.options.filter(el => field.value.includes(el.value))
        })
        :
        options.filter(el => field.value.includes(el.value))
    )
  }

  useEffect(() => {
    if (item.options?.length === 0) {
      item.getOptions()
        .then(res => {
          setOptions(res)
          getValues(res)
        })
    } else {
      setOptions(item.options)
      getValues(item.options)
    }
  }, [])

  useEffect(() => {
    if (isInit) {
  //    console.log('values', values)
      helpers.setFieldValue(field.name, (values.length > 0) ? values.map(el => el.value) : [])
    } else {
      setInit(true)
    }
  }, [values])

  return (
    <Select
      disabled={options.length === 0}
      {...field}
      onChange={(value, action) => {
        console.log(action)
        if (action.action === 'clear') {
          setValues([])
        } else if (action.action === 'select-option') {
          setValues( [...values, action.option] )
        } else if (action.action === 'remove-value') {
          setValues( [...values.filter(el => el !== action.removedValue)] )
        }
      }}
      value={values}
      options={options}
      formatGroupLabel={formatGroupLabel}
      isMulti={item.multiple || false}
      closeMenuOnSelect={!item.multiple || true}
      placeholder={item.placeholder}
    />
  )
}

export default Searchable
