import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuthContext } from '../utils/authContext.js'


import { routes } from '../utils/routes'

const PublicRoute = ({component: Component, restricted, ...rest}) => {
  const { isAuth } = useAuthContext()

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route {...rest} render={props => (
      isAuth && restricted ?
        <Redirect to={routes.DASHBOARD} />
        :
        <Component {...props} />
    )} />
  )
}

export default PublicRoute
