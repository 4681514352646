import { Slider, SliderTrack, SliderFilledTrack, SliderThumb } from '@chakra-ui/react'

const FillableSlider = ({ field, item, helpers }) => {

  return (
    <Slider
      {...field}
      onChange={value => helpers.setFieldValue(field.name, value)}
      aria-label="slider-ex-1"
      defaultValue={field.value ?? item.min}
      min={item.min ?? 1}
      max={item.max ?? 10}
      step={item.step ?? 1}>
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb boxSize={8}>
        {field.value || item.min}
      </SliderThumb>
    </Slider>
  )
}

export default FillableSlider
