import { InputGroup, InputLeftAddon, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from '@chakra-ui/react'

const Money = ({ field, item, helpers }) => {

  return (
    <InputGroup {...field}>
      <InputLeftAddon children="€" />
      <NumberInput
        precision={2}
        name={field.name}
        defaultValue={field.value || 0}
        min={0}
        >
        <NumberInputField style={{borderRadius: '0 .375rem .375rem 0'}}/>
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </InputGroup>
  )
}

export default Money
