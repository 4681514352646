import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Stack, Box, Heading } from '@chakra-ui/react'
import { user, exp, faq, association, school } from '../utils/fields'
import Header from '../components/Header'
import TheForm from '../components/TheForm'

const Edit = () => {
  const [ what, setWhat ] = useState(false)
  const [ fields, setFields ] = useState(false)

  let location = useLocation()


  useEffect(() => {
    let [ empty, admin, what, id ] = location.pathname.split('/')
    setWhat({
      what: what,
      id: id
    })

    if (what === 'user') {
      setFields(user)
    } else if (what === 'faq') {
      setFields(faq)
    } else if (what === 'event') {
      setFields(exp)
    } else if (what === 'association') {
      setFields(association)
    } else if (what === 'school') {
      setFields(school)
    }
  }, [])

  return(
    <Stack direction="column" width={["100%", "80%"]} spacing={10}>
      <Header what={what.what} />
      <Box borderRadius="lg" bg="gray.50" width="100%" p={[2, 10]} alignSelf="center">
        {fields && what &&
          <>
            <Heading as="h1" align="center" pt={5} pb={10}>{(what.id === 'new') ? fields.titleNew : fields.title}</Heading>
            <TheForm
              isMulti={fields.isMulti}
              rawFields={fields.fields}
              what={what} />
          </>
        }
      </Box>
    </Stack>
  )
}


export default Edit
