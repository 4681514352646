import { forwardRef } from 'react'
import { Button } from '@chakra-ui/react'
import { CalendarIcon, TimeIcon } from '@chakra-ui/icons'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import it from 'date-fns/locale/it'
registerLocale('it', it)

const Dating = ({ field, item, helpers }) => {

  return (
    <DatePicker
      selected={new Date(field.value || new Date())}
      onChange={(date) => helpers.setFieldValue(field.name, date)}
      showPopperArrow={false}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="Orario"
      dateFormat="dd MMMM yyyy - HH:mm"
      locale="it"
      customInput={<DateSelector />}
    />
  )
}

const DateSelector = forwardRef(({ value, onClick }, ref) => (
   <Button
      leftIcon={<CalendarIcon />}
      rightIcon={<TimeIcon />}
      variant="outline"
      onClick={onClick} ref={ref}>
     {value}
   </Button>
 ))

export default Dating
